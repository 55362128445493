import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { poems } from "./utils";
import "./styles.css";
import * as S from "./styes";
import { Poem } from "components/Poem";

interface IPoemInfoProps {
  show: boolean;
  content: string;
}

export const Poems: React.FC = () => {
  const location = useLocation();

  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const [displayLocation, setDisplayLocation] = useState(location);
  const [poemInfo, setPoemInfo] = useState<IPoemInfoProps>({
    show: false,
    content: "",
  });

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  const handlePoemClick = (content: string) => {
    setPoemInfo({
      show: true,
      content,
    });
  };

  const handleClosePoem = () => {
    setPoemInfo({
      show: false,
      content: "",
    });
  };

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <S.Poems>
        <S.Title>Chrysalian Poems</S.Title>

        <S.List>
          {poems.map((poem, index) => (
            <S.ListItem
              key={index}
              onClick={() => handlePoemClick(poem.content)}
            >
              {poem.title}
            </S.ListItem>
          ))}
        </S.List>

        <Link to="/bedroom">
          <S.Dagger src="Assets/dagger.webp" alt="Dagger" />
        </Link>
      </S.Poems>

      {poemInfo.show && (
        <Poem $content={poemInfo.content} handleClick={handleClosePoem} />
      )}
    </div>
  );
};
