import styled, { keyframes } from "styled-components";

export const diamondLoader = keyframes`
  0%, 10% {
    transform: translate(-64px, -64px) rotate(-45deg);
  }
  90%, 100% {
    transform: translate(0px, 0px) rotate(-45deg);
  }
`;

export const Loader = styled.span`
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
  overflow: hidden;
  display: flex;
  left: 50%;
  top: 50%;

  &::after {
    content: "";
    position: absolute;
    inset: 8px;
    margin: auto;
    background: #222b32;
  }

  &::before {
    content: "";
    position: absolute;
    inset: -15px;
    margin: auto;
    background: #de3500;
    animation: ${diamondLoader} 2s linear infinite;
  }
`;

export const Conitainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  z-index: 20;
`;

export const Text = styled.span`
  color: #ffffff;
  left: 49%;
  top: 60%;
  position: absolute;
  font-family: "Playball", cursive;
  font-size: 32px;

  @media (max-height: 430px) {
    left: 46%;
    top: 75%;
  }
`;
