import { api } from "./api";
import { IChaptersProps, ISignInProps, ISignUpProps } from "./types";

export const getChapters = async ({
  storyId,
  chapterNumber,
  language = "en",
}: IChaptersProps) => {
  try {
    const response = await api.get(
      `/api/Chapters/GetChapter/${storyId}?chapterNumber=${chapterNumber}&language=${language}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const signUp = async (data: ISignUpProps) => {
  try {
    const response = await api.post(
      `/api/Users/PostUser?email=${data.email}&password=${data.password}&username=${data.username}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const signIn = async (data: ISignInProps) => {
  try {
    const response = await api.get(
      `/api/Users/GetUser?email=${data.email}&password=${data.password}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
