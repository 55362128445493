import { createSlice } from "@reduxjs/toolkit";

interface IUserProps {
  username: string;
  email: string;
  reputation: number;
}

const getStoredUser = (): IUserProps | null => {
  const storedUser = localStorage.getItem("user");
  if (storedUser) {
    return JSON.parse(storedUser);
  }
  return null;
};

const storedUser: IUserProps | null = getStoredUser();

const initialState = {
  isLoading: false,
  dialog: {
    isOpen: false,
    title: "",
    message: "",
  },
  login: {
    isOpen: false,
    isLogged: false,
  },
  user: {
    username: storedUser?.username || "",
    email: storedUser?.email || "",
    reputation: storedUser?.reputation || 0,
  },
};

export const slice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    activateLoader: (state) => {
      state.isLoading = true;
    },
    deactivateLoader: (state) => {
      state.isLoading = false;
    },
    activateLoginModal: (state) => {
      state.login.isOpen = true;
    },
    deactivateLoginModal: (state) => {
      state.login.isOpen = false;
    },
    activateDialog: (state) => {
      state.dialog.isOpen = true;
    },
    deactivateDialog: (state) => {
      state.dialog.isOpen = false;
    },
    setDialogTitle: (state, action) => {
      state.dialog.title = action.payload;
    },
    setDialogMessage: (state, action) => {
      state.dialog.message = action.payload;
    },
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    resetUserState: (state) => {
      state.user = {
        username: "",
        email: "",
        reputation: 0,
      };
    },
    setLogingStatus: (state, action) => {
      state.login.isLogged = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  activateLoader,
  deactivateLoader,
  activateDialog,
  deactivateDialog,
  setDialogMessage,
  setDialogTitle,
  activateLoginModal,
  deactivateLoginModal,
  setUserInfo,
  resetUserState,
  setLogingStatus,
} = slice.actions;

export default slice.reducer;
