import React, { useState } from "react";
import { IEntriesProps } from "./types";
import { getChapters } from "services";
import { StoryChapter } from "services/types";
import { useDispatch } from "react-redux";
import {
  activateDialog,
  activateLoader,
  deactivateLoader,
  setDialogMessage,
  setDialogTitle,
} from "redux/slice";
import * as S from "./styles";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const Entries: React.FC<IEntriesProps> = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [entries, setEntries] = useState<StoryChapter>({
    chapterName: "",
    content: "",
  });
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const getEntries = async (chapterNumber: number) => {
    const currentLanguage = i18next.language;

    const body = {
      storyId: 2,
      chapterNumber: chapterNumber,
      language: currentLanguage,
    };

    dispatch(activateLoader());

    try {
      const response = await getChapters(body);

      if (response.status === 204) {
        dispatch(activateDialog());

        dispatch(
          setDialogMessage(
            "Oops! Parece que este conteudo ainda não está disponível em PT."
          )
        );
        dispatch(deactivateLoader());

        return;
      }

      setEntries(response.data);
      setShowHistory(true);
    } catch (error) {
      dispatch(activateDialog());
      dispatch(setDialogTitle("Oops!"));
      dispatch(setDialogMessage(t("apiError")));
    }

    dispatch(deactivateLoader());
  };

  const closeHistory = () => {
    setShowHistory(false);
  };

  return (
    <S.Container>
      <S.Title>{t("entries")}</S.Title>

      <S.EntriesWrapper>
        <S.EntrieBanner
          src="EntrysEN/entry1.webp"
          alt="First entry"
          onClick={() => getEntries(1)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry2.webp"
          alt="Second entry"
          onClick={() => getEntries(2)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry3.webp"
          alt="Third entry"
          onClick={() => getEntries(3)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry4.webp"
          alt="Fourth entry"
          onClick={() => getEntries(4)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry5.webp"
          alt="Fifth entry"
          onClick={() => getEntries(5)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry6.webp"
          alt="Sixth entry"
          onClick={() => getEntries(6)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry7.webp"
          alt="Seventh entry"
          onClick={() => getEntries(7)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry8.webp"
          alt="Eighth entry"
          onClick={() => getEntries(8)}
        />

        <S.EntrieBanner
          src="EntrysEN/entry9.webp"
          alt="Deviant entry"
          onClick={() => getEntries(9)}
        />
      </S.EntriesWrapper>

      <S.BackButton
        src="Assets/dagger.webp"
        alt="dagger Image as back button"
        onClick={handleClick}
      />

      {showHistory && (
        <S.HistoryContainer>
          <S.HistoryAuthor>Lady of Sin</S.HistoryAuthor>

          <S.HistorySubTitle>By Soul R. R.</S.HistorySubTitle>

          <S.HistoryTitle>
            {entries.chapterName?.replace(" - ", "\n")}
          </S.HistoryTitle>

          <S.HistoryContent
            dangerouslySetInnerHTML={{ __html: entries.content || "" }}
          ></S.HistoryContent>

          <S.HistoryEnd>
            {entries.chapterName?.split(" - ")[1]}: end
          </S.HistoryEnd>

          <S.HistoryBackButton
            src="Assets/dagger.webp"
            alt="dagger Image as back button"
            onClick={closeHistory}
          />
        </S.HistoryContainer>
      )}
    </S.Container>
  );
};
