import React from "react";
import { IAboutProps } from "./types";
import * as S from "./styles";
import { useTranslation } from "react-i18next";

export const About: React.FC<IAboutProps> = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("theTavern")}</S.Title>

      <S.Main>
        <S.SubTitle>{t("aboutTitle")}</S.SubTitle>

        <S.TextWrapper>
          <span>
            {t("aboutStory")}
            <a
              href="https://www.instagram.com/soulzinha/"
              target="_blank"
              rel="noreferrer"
            >
              Soul R. R.
            </a>
          </span>

          <span>
            {t("aboutIllustrations")}
            <a
              href="https://www.instagram.com/artful_ray/"
              target="_blank"
              rel="noreferrer"
            >
              Ray Ray
            </a>
            .
          </span>

          <span>
            {t("aboutSong")}
            <a
              href="https://www.instagram.com/foscarinikin/"
              target="_blank"
              rel="noreferrer"
            >
              Kin
            </a>
            .
          </span>
        </S.TextWrapper>

        <S.Info>
          {t("aboutThinksOne")}{" "}
          <a
            href="https://www.patreon.com/chrysalia_story"
            target="_blank"
            rel="noreferrer"
          >
            Soul's Patreon
          </a>{" "}
          {t("aboutThinksTwo")}
        </S.Info>
      </S.Main>

      <S.dagger
        src="Assets/dagger.webp"
        alt="Back button image"
        onClick={handleClick}
      />
    </S.Container>
  );
};
