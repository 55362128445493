import React from "react";
import { IPoemProps } from "./types";
import * as S from "./styles";

export const Poem: React.FC<IPoemProps> = ({ handleClick, $content }) => {
  return (
    <S.Container>
      <S.Poem $content={$content}></S.Poem>

      <S.BackButton
        src="Assets/dagger.webp"
        alt="dagger Image as back button"
        onClick={handleClick}
      />
    </S.Container>
  );
};
