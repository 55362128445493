import styled from "styled-components";
import { ITextProps } from "./types";

const Item = styled.img`
  position: absolute;
  object-fit: contain;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  gap: 64px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  font-family: "Special Elite", system-ui;

  a {
    color: #000;
    cursor: pointer;
  }

  @media (max-height: 430px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
`;

export const Main = styled.main`
  width: 37.5rem;
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-height: 430px) {
    height: 20rem;
    width: 20rem;
    background-size: cover;
  }
`;

export const dagger = styled.img`
  height: 10rem;
  margin-left: 32px;
  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;

export const Innkeeper = styled(Item)`
  width: 40rem;
  height: 40rem;

  top: 18%;
  left: 50%;

  @media (max-height: 430px) {
    width: 7rem;
    height: 7rem;
    top: 18%;
  }
`;

export const ChatBox = styled.div`
  width: 30rem;
  height: 10rem;
  background-color: #ffecab;
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
`;

export const TextWrapper = styled.p<ITextProps>`
  opacity: ${(props) => (props.$fade ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;
