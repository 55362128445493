export const LazsloTextsEN = [
  'Why, hello there. A returning customer, are we? Or a new one, perhaps.',
  'No matter. Some changes have been made recently, as you may or may not have noticed.',
  // 'The bedroom upstairs is now available to our VIP clients.',
  'You may also listen to our music in the new Chrysalian Songs page.',
  'But most importantly... the diary of one most dear to Nelissa is now available to be read.',
  'I am talking about Lady of Sin, of course. The black book on the table.',
  'Of course, many new Chrysalian Tales and Poems will follow this new book.',
  'I strongly encourage you to visit more often. Every week, a new entry of Lady of Sin will be made available.',
];

export const LazsloTextsPT = [
  'Ora, olá. Um cliente que regressa, não é? Ou um novo, talvez',
  'Não importa. Foram feitas algumas alterações recentemente, como pode ou não ter reparado.',
  // 'O quarto lá de cima está agora disponível para os nossos clientes VIP.',
  'Podem também ouvir a nossa música na nova página das Canções Crisálidas',
  'Mas o mais importante... o diário de uma das pessoas mais queridas de Nelissa está agora disponível para ser lido',
  'Estou a falar da Senhora do Pecado, claro. O livro preto em cima da mesa',
  'Claro que muitos novos contos e poemas de Chrysalian seguir-se-ão a este novo livro',
  'Aconselho-vos vivamente a visitarem-no mais vezes. Todas as semanas, uma nova entrada de Lady of Sin será disponibilizada',
];
