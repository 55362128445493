import styled, { css } from "styled-components";
import { IButtonProps } from "./types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ActiveButtonStyle = css`
  border: none;
`;

const InacctiveButtonStyle = css`
  border: 1px #c1c1c1 solid;
  background-color: #000000;
  color: #ffffffff;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dialog = styled.div`
  max-height: 50rem;
  position: relative;
  overflow-y: auto;
  -webkit-scrollbar {
    display: none; /* This will hide the scrollbar completely */
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 40rem;
  background-color: #0f1418;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 54px;
  padding: 24px;
  gap: 52px;

  @media (max-height: 430px) {
    max-height: 20rem;
    font-size: 24px;
    gap: 24px;
  }
`;

export const CloseButton = styled.button`
  padding: 20px;
  border-radius: 16px;
  border: none;
  background-color: #27333b;
  font-family: "Special Elite", system-ui;
  color: #f1f1f1;
  cursor: pointer;

  @media (max-height: 430px) {
    padding: 12px;
  }
`;

export const LanguageButtons = styled.div`
  display: flex;
  position: absolute;
  top: 48px;
  left: 36px;

  :nth-child(odd) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  :nth-child(even) {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  @media (max-width: 428px) {
    padding: 1em;
  }

  @media (max-width: 810px) {
    padding: 16px;
  }
`;

export const LangBtn = styled.button<IButtonProps>`
  display: flex;
  place-content: center;
  place-items: center;
  padding: 16px 28px;
  font-weight: bold;
  cursor: pointer;
  transition: box-shadow 0.3s;
  font-family: "Special Elite", system-ui;

  ${({ $isActive }) => ($isActive ? ActiveButtonStyle : InacctiveButtonStyle)}
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const UserIcon = styled(AccountCircleIcon)`
  color: #ffffff;
  font-size: 10rem !important;

  @media (max-height: 430px) {
    font-size: 5rem !important;
  }
`;

export const SignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  border: 1px solid #f1f1f1;
  border-radius: 16px;
`;

export const SignButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;

  :nth-child(1) {
    border-right: 1px solid #f1f1f1;
    border-top-left-radius: 15px;
  }
  :nth-child(2) {
    border-top-right-radius: 15px;
  }
`;

export const SignButton = styled.button<IButtonProps>`
  width: 100%;
  border: none;
  font-family: "Special Elite", system-ui;
  background-color: ${({ $isActive }) =>
    $isActive ? "#f1f1f1" : "transparent"};
  font-size: 16px;
  padding: 16px;
  color: ${({ $isActive }) => ($isActive ? "#000000" : "#f1f1f1")};
  cursor: pointer;
`;

export const PatreonButton = styled.button`
  width: max-content;
  padding: 12px;
  margin: 12px auto;
  font-size: 16px;
  font-family: "Special Elite", system-ui;
  cursor: pointer;
  border-radius: 16px;
  border: none;
  background-color: #27333b;
  color: #f1f1f1;
  user-select: none;
`;

export const SubmitButton = styled.button`
  width: max-content;
  padding: 12px;
  margin: 24px auto;
  font-size: 16px;
  font-family: "Special Elite", system-ui;
  cursor: pointer;
  border-radius: 16px;
  border: none;
  background-color: #27333b;
  color: #f1f1f1;
  user-select: none;
`;

export const LoggedUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const UserName = styled.span`
  color: #f1f1f1;
  font-size: 32px;
  font-family: "Special Elite", system-ui;
`;

export const Dagger = styled.img`
  height: 5rem;
  position: absolute;
  top: 24px;
  right: 24px;

  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;
