import styled from 'styled-components';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dialog = styled.div`
  height: 20rem;
  width: 40rem;
  background-color: #0f1418;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 54px;

  @media (max-height: 430px) {
    height: 16rem;
    width: 20rem;
    font-size: 24px;
  }
`;

export const Title = styled.h2`
  color: #f1f1f1;
  font-size: 16px;
  font-family: 'Special Elite', system-ui;
`;

export const Message = styled.p`
  color: #f1f1f1;
  font-size: 16px;
  font-family: 'Special Elite', system-ui;
  text-align: center;
  width: 80%;
`;

export const DialogIcon = styled(
  PriorityHighIcon,
)`
  background-color: #27333b;
  width: 5rem;
  border-radius: 50%;
`;

export const Button = styled.button`
  padding: 20px;
  border-radius: 16px;
  border: none;
  background-color: #27333b;
  font-family: 'Special Elite', system-ui;
  color: #f1f1f1;
  cursor: pointer;

  @media (max-height: 430px) {
    padding: 12px;
  }
`;
