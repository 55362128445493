import React from 'react';
import { Dragon } from 'assets/components/Dragon';
import * as S from './styles';
import { Link } from 'react-router-dom';

export const NotFound: React.FC = () => {
  return (
    <S.PageBody>
      <Dragon />

      <S.TextWrapper>
        <S.NotFoundText>
          Whoops... this page is not available
        </S.NotFoundText>

        <Link to='/'>
          <S.GoBack>Go back</S.GoBack>
        </Link>
      </S.TextWrapper>
    </S.PageBody>
  );
};
