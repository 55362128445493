import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 2.5%;
  right: 1.5%;
  z-index: 10;
  background-color: #1d232a;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
