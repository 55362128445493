import { GlobalStyle } from 'styles/global';
import Routes from 'routes';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import { SpeedInsights } from '@vercel/speed-insights/react';
import * as S from './styles';

const App = () => {
  return (
    <Provider store={store}>
      <S.AppWrapper>
        <Routes />

        <GlobalStyle />
        <SpeedInsights />
      </S.AppWrapper>
    </Provider>
  );
};

export default App;
