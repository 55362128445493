import styled from "styled-components";

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 0 0 0;
  width: 80%;
  gap: 24px;
`;

export const Input = styled.input`
  padding: 12px;
  background-color: #27333b;
  color: #f1f1f1;
  font-family: "Special Elite", system-ui;
  border: none;
  font-size: 16px;
`;

export const MiscWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const ForgotPassword = styled.button`
  color: #f1f1f1;
  background-color: transparent;
  border: none;
  font-family: "Special Elite", system-ui;
  font-size: 16px;
  cursor: pointer !important;
`;

export const CheckboxWrapper = styled.div`
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  user-select: none;
`;

export const CheckboxLabel = styled.label`
  font-size: 16px;
  color: #f1f1f1;
  font-family: "Special Elite", system-ui;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  width: max-content;
  padding: 12px;
  margin: 24px auto;
  font-size: 16px;
  font-family: "Special Elite", system-ui;
  cursor: pointer;
  border-radius: 16px;
  border: none;
  background-color: #27333b;
  color: #f1f1f1;
  user-select: none;
`;
