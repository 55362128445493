import styled from 'styled-components';

const Item = styled.img`
  position: absolute;
  object-fit: contain;
  display: block;
  background-position: center;
  background-repeat: no-repeat;

  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #000000);
    cursor: pointer;
  }
`;

export const Poems = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #fff5d4;
  flex-direction: column;
  position: relative;
`;

export const List = styled.ul`
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 24px;
`;

export const ListItem = styled.li`
  border: 1px solid #000000;
  border-radius: 8px;
  text-align: center;
  padding: 24px;
  font-size: 24px;
  font-family: 'Playball', system-ui;

  transition: all 0.5s ease-in-out;

  &:hover {
    color: #f1f1f1;
    background-color: #000000;
    cursor: pointer;
  }

  @media (max-height: 430px) {
    font-size: 12px;
    padding: 12px;
  }
`;

export const Title = styled.h2`
  font-family: 'Comfortaa', system-ui;
  font-size: 72px;
  width: 100%;
  padding-top: 64px;
  text-align: center;

  @media (max-height: 430px) {
    font-size: 24px;
    padding: 32px 0 8px;
  }
`;

export const Dagger = styled(Item)`
  width: 10rem;
  height: 10rem;

  bottom: 0;
  right: 10%;

  @media (max-height: 430px) {
    width: 5rem;
    height: 5rem;
    right: 5%;
  }
`;
