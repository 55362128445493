import { ITranslations } from "./types";

const en: ITranslations = {
  apiError:
    "An error occurred while trying to get the chapter. Please try again later.",
  close: "Close",
  welcomeTo: "Welcome to",
  theTavern: "The tavern",
  tutorialFirst:
    "This is an immersive experience! Explore this warm refuge to find tales from the world of Chrysalia- a land of dark fantasy and mystery.",
  tutorialSecond:
    "In case of confusion, perhaps talking to the bartender may help...",
  tutorialDummy: "Click, dummy",
  aboutTitle: "About us",
  aboutStory: "Story, writing, and some songs by ",
  aboutIllustrations: "Illustrations by ",
  aboutSong: "Deadman's Whisper song by ",
  aboutThinksOne:
    "We are incredibly happy to have you here! Even checking the website out is support, and we are very thankful for it. Check out ",
  aboutThinksTwo: " if you'd like to support us financially!",
  songsPageTitle: "Chrysalian Songs",
  songLullaby:
    "This is Aurora Kuvera's theme from Lady of Sin, the second entry to the Chrysalia universe. For more information, check out the Tavern at https://www.chrysalia.art/ and follow me on Instagram @chrysalia.story :3",
  songSkin:
    "A song that expands on the events of Ironskin, telling the origins of our protagonist. Read it now on my website https://www.chrysalia.art/ and follow my instagram @chrysalia.story for more content!",
  songScarlet:
    "This song expands on the universe of Chrysalia Read more about it on https://www.chrysalia.art/ and follow my instagram @chrysalia.story for updates! It was originally a poem, but I just kept singing it, so... here it is! òwó",
  entries: "Entries",
  acts: "Acts",
  holdUp: "Hold up!",
  patreonWarning:
    "The renovations upstairs seem to be taking longer than expected. The bedroom is not accessible at the moment. Please check back later. Thank you for your patience.",
  keepLogged: "Keep me logged in!",
  email: "E-mail",
  userName: "Username",
  password: "Password",
  confirmPassword: "Confirm password",
  forgotPassword: "Forgot Password? Click here!",
  signIn: "Sign In",
  signUp: "Sign Up",
  numberRequirement: "Password is 8-16 characters with no space",
  uppercaseRequirement: "Password must contain 1 uppercase letters",
  lowercaseRequirement: "Password must contain 1 lowercase letters",
  nonAlphaNumericRequirement:
    "Password must contain 1 non-alpha numeric number",
  lengthRequirement: "Password must contain 1 number (0-9)",
  welcomeUser: "Welcome, ",
  reputation: "Reputation ",
  logout: "Logout",
  patreonLink: "Link here your patreon account!",
  unavailableFeature: "It seems this feature is not available yet.",
};

export default en;
