import React, { useState } from "react";
import ImageMapper from "react-img-mapper";
import { areas } from "./areas";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { activateDialog, setDialogMessage, setDialogTitle } from "redux/slice";
import { About } from "components/About";
import { Entries } from "components/Entries";
import { Acts } from "components/Acts";
import { Poster } from "components/Poster";
import { Lazslo } from "components/Lazslo";
import { BackgroundMusic } from "components/BackgroundMusic";

export const TavernMapper: React.FC = () => {
  var w = window.innerWidth;
  const URL = "Backgrounds/tavern.webp";
  const MAP = {
    name: "tavern",
    areas: areas,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showAbout, setShowAbout] = useState<boolean>(false);
  const [showPoster, setShowPoster] = useState<boolean>(false);
  const [showEntries, setShowEntries] = useState<boolean>(false);
  const [playMusic, setPlayMusic] = useState<boolean>(false);
  const [showActs, setShowActs] = useState<boolean>(false);
  const [showLazslo, setShowLazslo] = useState<boolean>(false);

  const handleBookClick = () => {
    setShowEntries(!showEntries);
  };

  const handleDiscordClick = () => {
    window.open("https://discord.gg/8N3Rs6URvz", "_blank");
  };

  const handlePatreonClick = () => {
    window.open("https://www.patreon.com/chrysalia_story", "_blank");
  };

  const handleScrollsClick = () => {
    setShowActs(!showActs);
  };

  const handleCandleClick = () => {
    setShowAbout(!showAbout);
  };

  const handlePosterClick = () => {
    setShowPoster(!showPoster);
  };

  const handleLazsloClick = () => {
    setShowLazslo(!showLazslo);
  };

  const handleMusicClick = () => {
    setPlayMusic(!playMusic);
  };

  const handleBedroomDoor = () => {
    dispatch(activateDialog());
    dispatch(setDialogTitle(t("holdUp")));
    dispatch(setDialogMessage(t("patreonWarning")));
  };

  const handleMapClick = (title: string | undefined) => {
    switch (title) {
      case "patreon":
        handleBedroomDoor();
        break;
      case "discord":
        handleDiscordClick();
        break;
      case "scrolls":
        handleScrollsClick();
        break;
      case "book":
        handleBookClick();
        break;
      case "patreonBag":
        handlePatreonClick();
        break;
      case "candle":
        handleCandleClick();
        break;
      case "lazslo":
        handleLazsloClick();
        break;
      case "poster":
        handlePosterClick();
        break;
      case "guitar":
        navigate("/chrysalian-songs");
        break;
    }
  };

  return (
    <>
      <ImageMapper
        onClick={(area) => handleMapClick(area.id)}
        src={URL}
        map={MAP}
        responsive
        natural
        parentWidth={w}
      />

      <div style={{ position: "absolute", zIndex: 10, width: "100%" }}>
        {showAbout && <About handleClick={handleCandleClick} />}

        {showEntries && <Entries handleClick={handleBookClick} />}

        {showActs && <Acts handleClick={handleScrollsClick} />}

        {showPoster && <Poster handleClick={handlePosterClick} />}

        {showLazslo && <Lazslo handleClick={handleLazsloClick} />}

        {playMusic && <BackgroundMusic />}
      </div>
    </>
  );
};
