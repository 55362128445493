import React, { useState, useEffect } from "react";
import { ILazsloProps } from "./types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { LazsloTextsEN, LazsloTextsPT } from "./utils";
import * as S from "./styles";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const Lazslo: React.FC<ILazsloProps> = ({ handleClick }) => {
  const { t } = useTranslation();

  const [texts, setTexts] = useState<string[]>(LazsloTextsEN);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const currentLanguage = i18next.language;

    if (currentLanguage === "pt") {
      setTexts(LazsloTextsPT);
    } else {
      setTexts(LazsloTextsEN);
    }
  }, [t]);

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
      setFade(true);
    }, 500);
  };

  const handlePrevious = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex <= 0) {
          return texts.length - 1;
        } else {
          return prevIndex - 1;
        }
      });
      setFade(true);
    }, 500);
  };

  return (
    <S.Container>
      <S.Main>
        <S.ChatBox>
          <ChevronLeftIcon
            style={{
              cursor: "pointer",
              fontSize: "36px",
            }}
            onClick={handlePrevious}
          />

          <S.TextWrapper $fade={fade}>
            <div>{texts[currentIndex]}</div>
          </S.TextWrapper>

          <ChevronRightIcon
            style={{
              cursor: "pointer",
              fontSize: "36px",
            }}
            onClick={handleNext}
          />
        </S.ChatBox>
      </S.Main>

      <S.dagger
        src="Assets/dagger.webp"
        alt="Back button image"
        onClick={handleClick}
      />
    </S.Container>
  );
};
