import React from "react";
import * as S from "./styles";

export const Orientation: React.FC = () => {
  return (
    <S.OrientationBody>
      <S.Celphone src="Assets/cel.webp" alt="Celphone Image" />

      <S.OrientationText>Please rotate your device </S.OrientationText>
    </S.OrientationBody>
  );
};
