import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { TavernMapper, Tutorial } from "components";
import { useLocation } from "react-router-dom";
import { Loader } from "components/Loader";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { Dialog } from "components/Dialog";

import { patreon } from "services/api";

export const Tavern: React.FC = () => {
  const location = useLocation();
  const shared = useSelector((state: RootState) => state.shared);

  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const [displayLocation, setDisplayLocation] = useState(location);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  useEffect(() => {
    const tutorialLastViewed = localStorage.getItem("tutorialLastViewed");
    const now = new Date().getTime();

    if (tutorialLastViewed) {
      const lastViewedTime = parseInt(tutorialLastViewed, 10);
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day

      if (now - lastViewedTime > oneDay) {
        setShowTutorial(true); // Show the tutorial again
      } else {
        setShowTutorial(false); // Do not show the tutorial
      }
    } else {
      setShowTutorial(true); // If the tutorial has never been viewed, show it
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    const state = query.get("state");

    if (code && state) {
      if (code && state) {
        patreon
          .post("/token", {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            body: {
              client_id:
                "wm8GNGzv5-I1AeqWvCE6sZ3m4KcLZrcUP5CuLnyYRy4IhRee0jZ4BYlwK8OMewhp",
              client_secret:
                "9aNMhohe5UuJEaRlNy5wOxNZCde9tg2uH4rGfKXSW5PODLHFmaDpasgdTEthuA2",
              code: code,
              grant_type: "authorization_code",
              redirect_uri: "http://localhost:3000/",
            },
          })
          .then((response) => {
            // handle success
            console.log(response.data);
          })
          .catch((error) => {
            // handle error
            console.log(error);
          });
      }
    }
  }, [location]);

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <S.TavernBody>
        {showTutorial && <Tutorial />}

        {shared.isLoading && <Loader />}

        {shared.dialog.isOpen && <Dialog />}

        <TavernMapper />
      </S.TavernBody>
    </div>
  );
};
