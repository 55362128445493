import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateDialog,
  activateLoader,
  deactivateLoader,
  deactivateLoginModal,
  resetUserState,
  setDialogMessage,
  setDialogTitle,
  setLogingStatus,
  setUserInfo,
} from "redux/slice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as S from "./styles";
import { SignIn } from "components/SignIn";
import { SignUp } from "components/SignUp";
import { RootState } from "redux/store";
import { Loader } from "components/Loader";
import { useLocation } from "react-router-dom";

export const UserModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const shared = useSelector((state: RootState) => state.shared);

  const [buttonLang, setButtonLang] = useState("en");
  const [form, setForm] = useState({
    signIn: true,
    signUp: false,
  });
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    reputation: 0,
  });

  useEffect(() => {
    setUserInfo(shared.user);
  }, [shared.user]);

  useEffect(() => {
    if (shared.user.username !== "") {
      dispatch(setLogingStatus(true));
    }
  }, [shared]);

  useEffect(() => {
    const storedLang = localStorage.getItem("language");

    if (storedLang) {
      handlerLanguageChange(storedLang);
    }
  }, []);

  const handlerLanguageChange = (lang: string) => {
    i18next.changeLanguage(lang, (err) => {
      if (err) {
        console.log("something went wrong loading", err);
      } else {
        localStorage.setItem("language", lang);
        setButtonLang(lang);
      }
    });
  };

  const handleClose = () => {
    dispatch(deactivateLoginModal());
  };

  const handleSignInActivate = () => {
    setForm({ signIn: true, signUp: false });
  };

  const handleSignUpActivate = () => {
    setForm({ signIn: false, signUp: true });
  };

  const handleLogout = () => {
    dispatch(activateLoader());

    const reset = {
      username: "",
      email: "",
      reputation: 0,
    };

    localStorage.setItem("user", JSON.stringify(reset));
    dispatch(setLogingStatus(false));
    dispatch(resetUserState());
    dispatch(deactivateLoader());
  };

  const handlePatreon = () => {
    handleClose();
    dispatch(activateDialog());
    dispatch(setDialogTitle("Oops!"));
    dispatch(setDialogMessage(t("unavailableFeature")));

    return;
    const patreonAuthUrl = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=wm8GNGzv5-I1AeqWvCE6sZ3m4KcLZrcUP5CuLnyYRy4IhRee0jZ4BYlwK8OMewhp&redirect_uri=http://localhost:3000/&state=1`;
    window.location.href = patreonAuthUrl;
  };

  return (
    <S.Container>
      <S.Dialog>
        {shared.isLoading ? (
          <Loader />
        ) : (
          <>
            <S.UserIcon fontSize="inherit" color="inherit" />

            {!shared.login.isLogged ? (
              <S.SignWrapper>
                <S.SignButtonsWrapper>
                  <S.SignButton
                    $isActive={form.signIn}
                    onClick={handleSignInActivate}
                  >
                    {t("signIn")}
                  </S.SignButton>

                  <S.SignButton
                    $isActive={form.signUp}
                    onClick={handleSignUpActivate}
                  >
                    {t("signUp")}
                  </S.SignButton>
                </S.SignButtonsWrapper>

                {form.signIn ? <SignIn /> : <SignUp />}
              </S.SignWrapper>
            ) : (
              <S.LoggedUserInfo>
                <S.UserName>{t("welcomeUser") + userInfo.username}</S.UserName>

                <S.UserName>{t("reputation") + userInfo.reputation}</S.UserName>

                <S.PatreonButton onClick={handlePatreon}>
                  {t("patreonLink")}
                </S.PatreonButton>

                <S.SubmitButton onClick={handleLogout}>
                  {t("logout")}
                </S.SubmitButton>
              </S.LoggedUserInfo>
            )}

            <S.Footer>
              <S.LanguageButtons>
                <S.LangBtn
                  $isActive={buttonLang === "pt"}
                  onClick={() => handlerLanguageChange("pt")}
                >
                  PT
                </S.LangBtn>
                <S.LangBtn
                  $isActive={buttonLang === "en"}
                  onClick={() => handlerLanguageChange("en")}
                >
                  EN
                </S.LangBtn>
              </S.LanguageButtons>

              <S.Dagger
                src="Assets/dagger.webp"
                alt="Back button image"
                onClick={handleClose}
              />
            </S.Footer>
          </>
        )}
      </S.Dialog>
    </S.Container>
  );
};
