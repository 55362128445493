import React, { useState } from "react";
import { IActsProps } from "./types";
import { StoryChapter } from "services/types";
import { getChapters } from "services";
import { useDispatch } from "react-redux";
import {
  activateLoader,
  deactivateLoader,
  activateDialog,
  setDialogTitle,
  setDialogMessage,
} from "redux/slice";
import * as S from "./styles";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const Acts: React.FC<IActsProps> = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [acts, setActs] = useState<StoryChapter>({
    chapterName: "",
    content: "",
  });
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const getEntries = async (chapterNumber: number) => {
    const currentLanguage = i18next.language;

    const body = {
      storyId: 1,
      chapterNumber: chapterNumber,
      language: currentLanguage,
    };

    dispatch(activateLoader());

    try {
      const response = await getChapters(body);

      if (response.status === 204) {
        dispatch(activateDialog());

        dispatch(
          setDialogMessage(
            "Oops! Parece que este conteudo ainda não está disponível em PT."
          )
        );
        dispatch(deactivateLoader());

        return;
      }

      setActs(response.data);
      window.scrollTo(0, 0);
      setShowHistory(true);
    } catch (error) {
      dispatch(activateDialog());
      dispatch(setDialogTitle("Oops!"));
      dispatch(setDialogMessage(t("apiError")));
    }

    dispatch(deactivateLoader());
  };

  const closeHistory = () => {
    setShowHistory(false);
  };

  return (
    <S.Container>
      <S.Title>{t("acts")}</S.Title>

      <S.ActsWrapper>
        <S.ActBanner
          src="ActsEN/act1.webp"
          alt="First act"
          onClick={() => getEntries(1)}
        />

        <S.ActBanner
          src="ActsEN/act2.webp"
          alt="Second act"
          onClick={() => getEntries(2)}
        />

        <S.ActBanner
          src="ActsEN/act3.webp"
          alt="Third act"
          onClick={() => getEntries(3)}
        />

        <S.ActBanner
          src="ActsEN/act4.webp"
          alt="Fourth act"
          onClick={() => getEntries(4)}
        />

        <S.ActBanner
          src="ActsEN/act5.webp"
          alt="Fifth act"
          onClick={() => getEntries(5)}
        />

        <S.ActBanner
          src="ActsEN/act6.webp"
          alt="Sixth act"
          onClick={() => getEntries(6)}
        />

        <S.ActBanner
          src="ActsEN/act7.webp"
          alt="Seventh act"
          onClick={() => getEntries(7)}
        />

        <S.ActBanner
          src="ActsEN/act8.webp"
          alt="Eighth act"
          onClick={() => getEntries(8)}
        />

        <S.ActBanner
          src="ActsEN/actFinal.webp"
          alt="Final act"
          onClick={() => getEntries(9)}
        />
      </S.ActsWrapper>

      <S.BackButton
        src="Assets/dagger.webp"
        alt="dagger Image as back button"
        onClick={handleClick}
      />

      {showHistory && (
        <S.HistoryContainer>
          <S.HistoryAuthor>Ironskin</S.HistoryAuthor>

          <S.HistorySubTitle>By Soul R. R.</S.HistorySubTitle>

          <S.HistoryTitle>
            {acts.chapterName?.replace(" - ", "\n").trim()}
          </S.HistoryTitle>

          <S.HistoryContent
            dangerouslySetInnerHTML={{ __html: acts.content || "" }}
          ></S.HistoryContent>

          <S.HistoryEnd>{acts.chapterName} end</S.HistoryEnd>

          {acts.chapterName?.trim() === "Tourniquet" && (
            <>
              <S.TwoPaths>
                <S.Path
                  src="Assets/Soldier_Path.webp"
                  alt="Soldier path"
                  onClick={() => getEntries(11)}
                />

                <S.Path
                  src="Assets/Royal_Path.webp"
                  alt="Royal path"
                  onClick={() => getEntries(10)}
                />
              </S.TwoPaths>

              <S.Path src="Assets/chibiamber.webp" alt="Chibi Amber" />
            </>
          )}

          {acts.chapterName?.trim() === "Soldier Path" && (
            <S.TwoPaths>
              <S.Path
                src="Assets/Royal_Path.webp"
                alt="Royal path"
                onClick={() => getEntries(10)}
              />

              <S.Path
                src="Assets/Insanity.webp"
                alt="Insanity"
                onClick={() => getEntries(12)}
              />
            </S.TwoPaths>
          )}

          {acts.chapterName?.trim() === "Royal Path" && (
            <S.TwoPaths>
              <S.Path
                src="Assets/Soldier_Path.webp"
                alt="Soldier path"
                onClick={() => getEntries(11)}
              />
            </S.TwoPaths>
          )}

          <S.HistoryBackButton
            src="Assets/dagger.webp"
            alt="dagger Image as back button"
            onClick={closeHistory}
          />
        </S.HistoryContainer>
      )}
    </S.Container>
  );
};
