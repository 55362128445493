import React from 'react';
import * as S from './styles';
import { RootState } from 'redux/store';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { deactivateDialog } from 'redux/slice';

export const Dialog: React.FC = () => {
  const shared = useSelector(
    (state: RootState) => state.shared,
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(deactivateDialog());
  };

  return (
    <S.Container>
      <S.Dialog>
        <S.DialogIcon fontSize='inherit' />
        <S.Title>{shared.dialog.title}</S.Title>

        <S.Message>
          {shared.dialog.message}
        </S.Message>

        <S.Button onClick={handleClose}>
          Ok!
        </S.Button>
      </S.Dialog>
    </S.Container>
  );
};
