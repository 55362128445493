import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ISignInFormProps } from "./types";
import { emailRegex, passwordRegex } from "utils";
import { signIn } from "services";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { useDispatch } from "react-redux";
import {
  activateLoader,
  deactivateLoader,
  setLogingStatus,
  setUserInfo,
} from "redux/slice";

export const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<ISignInFormProps>();
  const dispatch = useDispatch();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const onSubmit: SubmitHandler<ISignInFormProps> = async (data) => {
    dispatch(activateLoader());

    const userInfo = {
      email: data.email,
      password: data.password,
    };

    const response = await signIn(userInfo);

    dispatch(setUserInfo(response.data));

    if (data.keepLogged) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    dispatch(setLogingStatus(true));
    dispatch(deactivateLoader());
  };

  return (
    <S.FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <S.InputWrapper>
        <S.Input
          type="text"
          placeholder={t("email")}
          onKeyDown={handleKeyDown}
          {...register("email", { required: true, pattern: emailRegex })}
        />
        <S.Input
          type="password"
          placeholder={t("password")}
          maxLength={16}
          onKeyDown={handleKeyDown}
          {...register("password", {
            required: true,
            pattern: passwordRegex,
          })}
        />
      </S.InputWrapper>

      <S.MiscWrapper>
        <S.ForgotPassword>{t("forgotPassword")}</S.ForgotPassword>

        <S.CheckboxWrapper>
          <input type="checkbox" id="keepLogged" {...register("keepLogged")} />
          <S.CheckboxLabel htmlFor="keepLogged">
            {t("keepLogged")}
          </S.CheckboxLabel>
        </S.CheckboxWrapper>
      </S.MiscWrapper>

      <S.SubmitButton type="submit">Sign In</S.SubmitButton>
    </S.FormWrapper>
  );
};
