interface Poem {
  type: number;
  title: string;
  content: string;
}

export const poems: Poem[] = [
  {
    type: 0,
    title: 'Steelflesh Maiden',
    content: 'Poems/SteelfleshMaiden.png',
  },
  {
    type: 0,
    title: 'Scarlet Locks',
    content: 'Poems/ScarletLocks.png',
  },
  {
    type: 0,
    title: 'Bodies',
    content: 'Poems/Bodies.png',
  },
  {
    type: 1,
    title: 'Tough Skin',
    content: 'Poems/ToughSkin.png',
  },
  {
    type: 1,
    title: 'Loneliness',
    content: 'Poems/Loneliness.png',
  },
  {
    type: 1,
    title: 'Memories',
    content: 'Poems/Memories.png',
  },
  {
    type: 2,
    title: 'Strength of Demons',
    content: 'Poems/StrengthOfDemons.png',
  },
  {
    type: 2,
    title: 'Godspeed',
    content: 'Poems/Godspeed.png',
  },
  {
    type: 2,
    title: 'Adamas',
    content: 'Poems/Adamas.png',
  },
  {
    type: 3,
    title: 'Demon Prayer, pt. I',
    content: 'Poems/DemonPrayerPtI.png',
  },
  {
    type: 3,
    title: 'Demon Prayer, pt. II',
    content: 'Poems/DemonPrayerPtII.png',
  },
  {
    type: 3,
    title: 'Love?',
    content: 'Poems/Love.png',
  },
  {
    type: 4,
    title: 'Lethargy',
    content: 'Poems/Lethargy.png',
  },
  {
    type: 4,
    title: 'Paranoia',
    content: 'Poems/Paranoia.png',
  },
  {
    type: 4,
    title: 'Abyss',
    content: 'Poems/Abyss.png',
  },
];
