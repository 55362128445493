import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  gap: 64px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  font-family: "Special Elite", system-ui;

  a {
    color: #000;
    cursor: pointer;
  }

  @media (max-height: 430px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
`;

export const Main = styled.main`
  background-color: #fff5d4;
  background-image: url("Assets/posterImage.webp");
  background-repeat: no-repeat;
  width: 37.5rem;
  height: 37.5rem;
  padding: 24px 36px 36px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-height: 430px) {
    height: 20rem;
    width: 20rem;
    background-size: cover;
  }
`;

export const dagger = styled.img`
  height: 10rem;

  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;
