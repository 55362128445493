import React from "react";
import { IAboutProps } from "./types";
import * as S from "./styles";

export const Poster: React.FC<IAboutProps> = ({ handleClick }) => {
  return (
    <S.Container>
      <S.Main></S.Main>

      <S.dagger
        src="Assets/dagger.webp"
        alt="Back button image"
        onClick={handleClick}
      />
    </S.Container>
  );
};
