import React from "react";
import * as S from "./styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Songs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("songsPageTitle")}</S.Title>

      <S.SongsList>
        <S.SongWrapper>
          <S.SongDisplay
            src="https://www.youtube.com/embed/WUlGNd2bgvM?si=DriJVU-gFr0lG2BS"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></S.SongDisplay>

          <S.SongTitle>Soul R. R. - Lullaby of Sin</S.SongTitle>

          <S.SongInfo>{t("songLullaby")}</S.SongInfo>
        </S.SongWrapper>

        <S.SongWrapper>
          <S.SongDisplay
            src="https://www.youtube.com/embed/2NfYUAaZtk8?si=3P7IAoNzu7XV3X74"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></S.SongDisplay>

          <S.SongTitle>Soul R. R. - Tough Skin</S.SongTitle>

          <S.SongInfo>{t("songSkin")}</S.SongInfo>
        </S.SongWrapper>

        <S.SongWrapper>
          <S.SongDisplay
            src="https://www.youtube.com/embed/OcWZpLJFdIA?si=IcKzJaIFI8f8A2AX"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></S.SongDisplay>

          <S.SongTitle>Soul R. R. - Scarlet Locks</S.SongTitle>

          <S.SongInfo>{t("songScarlet")}</S.SongInfo>
        </S.SongWrapper>
      </S.SongsList>

      <Link to="/">
        <S.BackButton
          src="Assets/dagger.webp"
          alt="dagger Image as back button"
        />
      </Link>
    </S.Container>
  );
};
