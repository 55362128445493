import styled from 'styled-components';

export const AppWrapper = styled.div`
  position: relative;

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;
