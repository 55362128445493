import styled from 'styled-components';

export const PageBody = styled.div`
  background-color: #40acf1;
  width: 100%;
  height: 100vh;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 48px;
`;

export const TextWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  @media (max-height: 430px) {
    gap: 20px;
  }
`;

export const NotFoundText = styled.p`
  color: #ffffff;

  text-align: center;
  font-family: 'Concert One';
  font-size: 88px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px;
  user-select: none;

  @media (max-height: 430px) {
    font-size: 32px;
    line-height: 52px;
  }
`;

export const GoBack = styled.button`
  padding: 32px;
  border-radius: 12px;
  background-color: transparent;
  border: 4px solid #fff;
  color: #fff;
  font-size: 20px;
  cursor: pointer;

  @media (max-height: 430px) {
    padding: 12px;
    font-size: 12px;
  }
`;
