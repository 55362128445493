import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Tavern, NotFound, Bedroom, Songs, Poems } from "pages";
import { useOrientation } from "@uidotdev/usehooks";
import { isMobile } from "react-device-detect";
import { Orientation, UserModal, UserIcon } from "components";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const Layout = () => {
  const orientation = useOrientation();
  const shared = useSelector((state: RootState) => state.shared);
  if (orientation.type === "portrait-primary" && isMobile) {
    return <Orientation />;
  }

  return (
    <>
      <UserIcon />
      {shared.login.isOpen && <UserModal />}
      <Outlet />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Tavern />,
      },
      {
        path: "bedroom",
        element: <Bedroom />,
      },
      {
        path: "poems",
        element: <Poems />,
      },
      {
        path: "chrysalian-songs",
        element: <Songs />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const Routes = () => <RouterProvider router={router} />;

export default Routes;
