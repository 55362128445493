import styled from 'styled-components';

export const OrientationBody = styled.div`
  background-color: #40acf1;
  display: flex;
  gap: 48px;
  overflow: hidden;
  flex-direction: row-reverse;
  height: 100%;
  touch-action: none;
  justify-content: center;
  padding-right: 25%;
`;

export const Celphone = styled.img`
  width: 40%;
  margin: 0 20px 25px 0;

  @media (max-width: 430px) {
    width: 20rem;
  }
`;

export const OrientationText = styled.p`
  font-size: 36px;
  align-self: center;
  text-align: center;
  transform: rotate(90deg);
  height: 50%;
`;
