import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  @media (max-height: 430px) {
    flex-direction: row;
  }
`;

export const Title = styled.h2`
  color: #ffffff;
  font-family: "Playball", cursive;
  font-size: 60px;

  @media (max-height: 430px) {
    display: none;
  }
`;

export const EntriesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);

  & > :nth-child(3n + 2) {
    padding-top: 64px;
  }

  @media (max-height: 430px) {
    padding-bottom: 10px;
  }
`;

export const EntrieBanner = styled.img`
  width: 15rem;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }

  @media (max-height: 430px) {
    width: 8rem;
  }
`;

export const BackButton = styled.img`
  width: 10rem;
  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;

export const HistoryBackButton = styled.img`
  width: 10rem;
  transition: box-shadow 0.5s ease-in-out;
  margin: 0 0 64px 0;
  position: sticky;
  bottom: 0vw;
  left: 0vw;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;

export const HistoryContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  width: 100%;
  height: 100vh;
  z-index: 20;
  display: grid;
`;

export const HistoryTitle = styled.h2`
  color: #dbdbdb;
  margin: 32px auto 0;
  font-size: 25px;
  padding: 32px 0;
  font-family: "Special Elite", system-ui;
  white-space: pre-wrap;
  line-height: 3rem;
  text-align: center;
`;

export const HistoryAuthor = styled.h2`
  color: #dbdbdb;
  margin: 32px auto 0;
  font-size: 25px;
  padding: 32px 0 0;
  font-family: "Special Elite", system-ui;
  text-align: center;
`;

export const HistorySubTitle = styled.h2`
  color: #dbdbdb;
  margin: 32px auto 0;
  font-size: 18px;
  font-family: "Special Elite", system-ui;
  text-align: center;
`;

export const HistoryContent = styled.p`
  color: #dbdbdb;
  font-size: 20px;
  line-height: 2em;
  width: 75%;
  font-family: "Special Elite", system-ui;
  white-space: pre-wrap;
  text-align: justify;
  margin: 0 auto;

  i {
    font-style: italic;
  }

  b {
    font-weight: bold;
  }
`;

export const HistoryEnd = styled.span`
  color: #dbdbdb;
  font-size: 20px;
  line-height: 2em;
  font-family: "Special Elite", system-ui;
  text-align: end;
  padding: 24px 132px 24px 0;
`;
