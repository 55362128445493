export const areas = [
  {
    id: "patreon",
    title: "Patreon",
    shape: "poly",
    name: "patreon",
    fillColor: "#ffffff50",
    coords: [705, 200, 535, 200, 535, 530, 615, 620, 710, 530],
  },
  {
    id: "discord",
    title: "discord",
    shape: "poly",
    name: "discord",
    fillColor: "#ffffff50",
    coords: [680, 650, 675, 650, 665, 650, 580, 720, 545, 750, 610, 780],
  },
  {
    id: "scrolls",
    title: "scrolls",
    shape: "poly",
    name: "scrolls",
    fillColor: "#ffffff50",
    coords: [900, 700, 790, 670, 755, 680, 735, 750, 850, 780, 920, 750],
  },
  {
    id: "book",
    title: "book",
    shape: "poly",
    name: "book",
    fillColor: "#ffffff50",
    coords: [1100, 700, 1030, 665, 955, 720, 1020, 780, 1120, 780],
  },
  {
    id: "patreonBag",
    title: "patreonBag",
    shape: "poly",
    name: "patreonBag",
    fillColor: "#ffffff50",
    coords: [1265, 680, 1180, 710, 1180, 770, 1280, 760],
  },
  {
    id: "candle",
    title: "candle",
    shape: "poly",
    name: "candle",
    fillColor: "#ffffff50",
    coords: [1372, 550, 1373, 550, 1340, 750, 1450, 780, 1470, 740],
  },
  {
    id: "lazslo",
    title: "lazslo",
    shape: "poly",
    name: "lazslo",
    fillColor: "#ffffff50",
    coords: [1260, 240, 1190, 230, 1170, 260, 1185, 295, 1188, 345, 1175, 520, 1275, 520],
  },
  {
    id: "poster",
    title: "poster",
    shape: "poly",
    name: "poster",
    fillColor: "#ffffff50",
    coords: [990, 230, 1125, 230, 1105, 385, 985, 380],
  },
  {
    id: "guitar",
    title: "guitar",
    shape: "poly",
    name: "guitar",
    fillColor: "#ffffff50",
    coords: [875, 190, 905, 190, 935, 420, 850, 420, 860, 315, 900, 310, 860, 345],
  },
];
