import styled from "styled-components";

export const TavernBody = styled.div`
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  overflow-y: hidden;
  z-index: 0;
`;
