import React, { useEffect, useState } from "react";
import * as S from "./styes";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";

export const Bedroom: React.FC = () => {
  const location = useLocation();

  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const [displayLocation, setDisplayLocation] = useState(location);

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <S.Bedroom>
        <Link to="/poems">
          <S.Poems src="Assets/stackofletters.webp" alt="Pile of poems" />
        </Link>

        <Link to="/">
          <S.Dagger src="Assets/dagger.webp" alt="Dagger" />
        </Link>
      </S.Bedroom>
    </div>
  );
};
