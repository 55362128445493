import React, { useState } from "react";
import * as S from "./styles";
import { useTranslation } from "react-i18next";

export const Tutorial: React.FC = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(true);

  const handleTutorialExit = () => {
    setIsVisible(false);

    const now = new Date();
    localStorage.setItem("tutorialLastViewed", now.getTime().toString());
  };

  return (
    <S.TutorialBody $isVisible={isVisible}>
      <S.WelcomeText>{t("welcomeTo")}</S.WelcomeText>

      <S.TutorialTitle>{t("theTavern")}</S.TutorialTitle>

      <S.TutorialText>{t("tutorialFirst")}</S.TutorialText>

      <S.DummyWrapper>
        <S.TutorialText>{t("tutorialDummy")}</S.TutorialText>

        <S.TutorialButton
          src="Assets/tavern_door.webp"
          alt="Door image"
          draggable={false}
          onClick={handleTutorialExit}
        />
      </S.DummyWrapper>

      <S.TutorialText>{t("tutorialSecond")}</S.TutorialText>
    </S.TutorialBody>
  );
};
