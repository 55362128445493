import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff5d4;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-height: 430px) {
    height: 100%;
  }
`;

export const Title = styled.h2`
  color: #000000;
  font-size: 60px;
  text-shadow: #ffffff -1px -1px 0px,
    #ffffff -1px 1px 0px, #ffffff 1px 1px 0px,
    #ffffff 1px -1px 0px;

  @media (max-height: 430px) {
    font-size: 24px;
    padding-top: 24px;
  }
`;

export const SongsList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-height: 430px) {
    padding-top: 24px;
  }
`;

export const SongWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-height: 430px) {
    width: 30%;
  }
`;

export const SongDisplay = styled.iframe`
  width: 23.5rem;
  height: 15rem;

  @media (max-height: 430px) {
    width: 18rem;
    height: 12rem;
  }
`;

export const SongTitle = styled.h3`
  font-family: 'Special Elite', system-ui;
  text-align: center;
  color: #000000;
`;

export const SongInfo = styled.div`
  color: #000000;
  font-family: 'Special Elite', system-ui;
  font-size: 12px;
  line-height: 20.04px;
  width: 80%;
  margin: 0 auto;
  text-align: center;

  @media (max-height: 430px) {
    line-height: 10px;
    /* width: 100%; */
  }
`;

export const BackButton = styled.img`
  width: 10rem;
  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #000);
    cursor: pointer;
  }
`;
