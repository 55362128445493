import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ISignUpForm } from "./types";
import { emailRegex, passwordRegex } from "utils";
import { signUp } from "services";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { useDispatch } from "react-redux";
import { setLogingStatus, setUserInfo } from "redux/slice";

export const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch } = useForm<ISignUpForm>();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState({
    show: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const password = watch("password");
  const passwordRequirements = {
    number: /\d/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    nonAlphaNumeric: /[^a-zA-Z0-9]/.test(password),
    length: /^[\S]{8,16}$/.test(password),
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const onSubmit: SubmitHandler<ISignUpForm> = async (data) => {
    setIsLoading(true);
    const userInfo = {
      email: data.email,
      username: data.username,
      password: data.password,
    };

    try {
      const response = await signUp(userInfo);

      dispatch(setUserInfo(response.data));
      dispatch(setLogingStatus(true));
    } catch (error) {
      const errorMessage = (error as any)?.response?.data.detail;

      setErrorMessage({ show: true, message: errorMessage });
    }
    setIsLoading(false);
  };

  return (
    <S.FormWrapper>
      <S.InputWrapper onSubmit={handleSubmit(onSubmit)}>
        <S.Input
          type="text"
          placeholder={t("email")}
          onKeyDown={handleKeyDown}
          {...register("email", { required: true, pattern: emailRegex })}
          disabled={isLoading}
        />

        <S.Input
          type="text"
          placeholder={t("userName")}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          {...register("username", { required: true })}
        />

        <S.Input
          type="password"
          placeholder={t("password")}
          disabled={isLoading}
          maxLength={16}
          onKeyDown={handleKeyDown}
          {...register("password", {
            required: true,
            pattern: passwordRegex,
          })}
        />

        <ul>
          <S.PasswordRequirements $isValid={passwordRequirements.number}>
            {t("numberRequirement")}
          </S.PasswordRequirements>

          <S.PasswordRequirements $isValid={passwordRequirements.uppercase}>
            {t("uppercaseRequirement")}
          </S.PasswordRequirements>

          <S.PasswordRequirements $isValid={passwordRequirements.lowercase}>
            {t("lowercaseRequirement")}
          </S.PasswordRequirements>

          <S.PasswordRequirements
            $isValid={passwordRequirements.nonAlphaNumeric}
          >
            {t("nonAlphaNumericRequirement")}
          </S.PasswordRequirements>

          <S.PasswordRequirements $isValid={passwordRequirements.length}>
            {t("lengthRequirement")}
          </S.PasswordRequirements>
        </ul>

        <S.Input
          disabled={isLoading}
          type="password"
          placeholder={t("confirmPassword")}
          maxLength={16}
          onKeyDown={handleKeyDown}
          {...register("confirmPassword", {
            required: true,
            pattern: passwordRegex,
          })}
        />

        {errorMessage.show && (
          <S.ErrorMessage>{errorMessage.message}</S.ErrorMessage>
        )}

        {isLoading ? (
          <S.LoaderWrapper>
            <S.Loader />
          </S.LoaderWrapper>
        ) : (
          <S.SubmitButton disabled={isLoading} type="submit">
            {t("signUp")}
          </S.SubmitButton>
        )}
      </S.InputWrapper>
    </S.FormWrapper>
  );
};
