import styled from 'styled-components';

const Item = styled.img`
  position: absolute;
  object-fit: contain;
  display: block;
  background-position: center;
  background-repeat: no-repeat;

  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;

export const Bedroom = styled.div`
  background-image: url('bedroom.webp');
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  z-index: 0;
`;

export const Poems = styled(Item)`
  width: 7rem;
  height: 7rem;

  bottom: 25%;
  left: 50%;

  @media (max-height: 430px) {
    width: 3rem;
    height: 3rem;
    bottom: 23%;
  }
`;

export const Dagger = styled(Item)`
  width: 10rem;
  height: 10rem;

  bottom: 0;
  right: 10%;

  @media (max-height: 430px) {
    width: 5rem;
    height: 5rem;
  }
`;
