import { ITranslations } from "./types";

const pt: ITranslations = {
  apiError:
    "Ocorreu um erro ao tentar obter o capítulo. Por favor, tente novamente mais tarde",
  close: "Fechar",
  theTavern: "Taberna",
  welcomeTo: "Bem-vindo a",
  tutorialFirst:
    "Esta é uma experiência imersiva! Explora este refúgio acolhedor para encontrares contos do mundo de Chrysalia - uma terra de fantasia sombria e mistério",
  tutorialSecond:
    "Em caso de confusão, talvez falar com o empregado do bar possa ajudar...",
  tutorialDummy: "Clica, boneco",
  aboutTitle: "Sobre nós",
  aboutStory: "História, escrita e algumas canções por ",
  aboutIllustrations: "Ilustrações por ",
  aboutSong: "Canção Deadman's Whisper por ",
  aboutThinksOne:
    "Estamos incrivelmente felizes por vos ter aqui! Até mesmo a visita ao site é um apoio, e estamos muito gratos por isso. Confira ",
  aboutThinksTwo: " se nos quiser apoiar financeiramente!",
  songsPageTitle: "Canções de Chrysalian",
  songLullaby:
    "Este é o tema de Aurora Kuvera de Lady of Sin, a segunda entrada no universo Chrysalia. Para mais informações, visita a Taverna em https://www.chrysalia.art/ e segue-me no Instagram @chrysalia.story :3",
  songSkin:
    "Uma canção que expande os eventos de Ironskin, contando as origens do nosso protagonista. Lê-a agora no meu site https://www.chrysalia.art/ e segue-me no Instagram @chrysalia.story para mais conteúdos!",
  songScarlet:
    "Esta canção expande o universo de Chrysalia. Lê mais sobre ela em https://www.chrysalia.art/ e segue o meu instagram @chrysalia.story para mais actualizações! Originalmente era um poema, mas eu não parava de o cantar, por isso... aqui está! òwó",
  entries: "Entradas",
  acts: "Atos",
  holdUp: "Espere!",
  patreonWarning:
    "As reformas no andar de cima parecem estar demorando mais do que o esperado. O quarto não está acessível no momento. Por favor, volte mais tarde. Obrigado por sua paciência.",
  keepLogged: "Me mantenha logado!",
  email: "E-mail",
  userName: "Nome de usuário",
  password: "Senha",
  confirmPassword: "Confirme a senha",
  forgotPassword: "Esqueceu sua senha? Clique aqui!",
  signIn: "Logar",
  signUp: "Cadastre-se",
  numberRequirement: "A senha deve ter 8 a 16 caracteres, sem espaço",
  uppercaseRequirement: "A senha deve conter 1 letra maiúscula",
  lowercaseRequirement: "A senha deve conter 1 letra minúscula",
  nonAlphaNumericRequirement: "A senha deve conter 1 simbolo",
  lengthRequirement: "A senha deve conter 1 número (0-9)",
  welcomeUser: "Bem vindo, ",
  reputation: "Reputação ",
  logout: "Sair",
  patreonLink: "Vincule aqui a sua conta no patreon!",
  unavailableFeature: "Parece que esta função ainda não está disponível.",
};

export default pt;
