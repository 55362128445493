import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from 'react-redux';
import { activateLoginModal } from 'redux/slice';
import * as S from './styles';

export const UserIcon: React.FC = () => {
  const dispatch = useDispatch();

  const openLoginModal = () => {
    dispatch(activateLoginModal());
  };

  return (
    <S.Container>
      <AccountCircleIcon
        fontSize='inherit'
        color='inherit'
        style={{
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '2.5rem',
        }}
        onClick={openLoginModal}
      />
    </S.Container>
  );
};
