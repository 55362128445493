import styled from "styled-components";
import { ITutorialBody } from "./types";

export const TutorialBody = styled.div<ITutorialBody>`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 72px;
  gap: 24px;
  z-index: 20;
  position: absolute;
`;

export const DummyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  @media (max-height: 430px) {
    height: 20%;
    justify-content: center;
  }
`;

export const TutorialButton = styled.img`
  width: 6rem;
  object-fit: contain;
  display: block;

  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }

  @media (max-height: 430px) {
    width: 5rem;
  }
`;

export const TutorialTitle = styled.h2`
  letter-spacing: 0.05em;
  color: #ffffff;
  font-weight: 500;
  font-size: 108px;
  line-height: 0.9em;
  text-align: center;
  font-family: "Playball", cursive !important;

  text-align: center;
  text-shadow: 0 0 5px #fff, /* White glow */ 0 0 10px #fff,
    /* White glow */ 0 0 20px #ff1177, /* Inner pink glow */ 0 0 30px #ff1177,
    /* Medium pink glow */ 0 0 40px #ff1177,
    /* Outer pink glow */ 0 0 50px #ff1177,
    /* Extra Outer pink glow */ 0 0 60px #ff1177,
    /* Farthest pink glow */ 0 0 70px #ff1177; /* Most distant pink glow */

  @media (max-height: 430px) {
    font-size: 40px;
  }
`;

export const TutorialText = styled.p`
  font-size: 20px;
  letter-spacing: normal;
  line-height: 1.4em;
  text-align: center;
  color: #d30202;
  font-family: "Comfortaa", sans-serif;
`;

export const WelcomeText = styled.span`
  letter-spacing: -0.1em;
  text-shadow: rgba(0, 0, 0, 0.498039) -1px -1px 0px,
    rgba(0, 0, 0, 0.498039) -1px 1px 0px, rgba(0, 0, 0, 0.498039) 1px 1px 0px,
    rgba(0, 0, 0, 0.498039) 1px -1;
  color: #d30202;
  font-size: 53px;
  text-align: center;
  font-family: "Special Elite", system-ui;

  @media (max-height: 430px) {
    font-size: 2rem;
  }
`;
