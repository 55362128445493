import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import pt from "./pt";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
};

const storedLang = localStorage.getItem("language");

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: storedLang || "en",
    fallbackLng: storedLang || "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
