import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 64px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  font-family: 'Special Elite', system-ui;

  a {
    color: #000;
    cursor: pointer;
  }

  @media (max-height: 430px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    gap: 0;
  }
`;

export const Title = styled.h3`
  letter-spacing: 0.05em;
  color: #ffffff;
  font-weight: 500;
  font-size: 48px;
  line-height: 0.9em;
  text-align: center;
  font-family: 'Playball', system-ui;

  text-align: center;
  text-shadow: 0 0 5px #fff,
    /* White glow */ 0 0 10px #fff,
    /* White glow */ 0 0 20px #ff1177,
    /* Inner pink glow */ 0 0 30px #ff1177,
    /* Medium pink glow */ 0 0 40px #ff1177,
    /* Outer pink glow */ 0 0 50px #ff1177,
    /* Extra Outer pink glow */ 0 0 60px #ff1177,
    /* Farthest pink glow */ 0 0 70px #ff1177; /* Most distant pink glow */

  @media (max-height: 430px) {
    display: none;
  }
`;

export const Main = styled.main`
  background-color: #fff5d4;
  width: 40%;
  height: 40%;
  padding: 24px 36px 36px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-height: 430px) {
    width: 90%;
    height: 90%;
    margin-left: 64px;
  }
`;

export const SubTitle = styled.h4`
  text-shadow: #ffffff 0px 0px 6px;
  font-size: 33px;
`;

export const TextWrapper = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
`;

export const Info = styled.div`
  font-size: 18px;
`;

export const dagger = styled.img`
  height: 10rem;

  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;
