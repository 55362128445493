import styled from 'styled-components';

import { IPoemProps } from './types';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  @media (max-height: 430px) {
    flex-direction: row;
  }
`;

export const Poem = styled.div<IPoemProps>`
  background-image: url(${({ $content }) =>
    $content});

  background-size: cover;
  width: 40rem;
  height: 40rem;

  @media (max-height: 430px) {
    width: 20rem;
    height: 20rem;
  }
`;

export const BackButton = styled.img`
  width: 10rem;
  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 10px #ffffff);
    cursor: pointer;
  }
`;
