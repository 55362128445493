import styled from "styled-components";
import { IPasswordItemProps } from "./types";
import { diamondLoader } from "components/Loader/styles";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

export const InputWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin: 48px 0 0 0;
  width: 80%;
  gap: 24px;
`;

export const Input = styled.input`
  padding: 12px;
  background-color: #27333b;
  color: #f1f1f1;
  font-family: "Special Elite", system-ui;
  border: none;
  font-size: 16px;
`;

export const PasswordRequirements = styled.li<IPasswordItemProps>`
  color: ${({ $isValid }) => ($isValid ? "#00FF00" : "#f1f1f1")};
  font-family: "Special Elite", system-ui;
  list-style-type: disc;
  font-size: 16px;
`;

export const SubmitButton = styled.button`
  width: max-content;
  padding: 12px;
  margin: 24px auto;
  font-size: 16px;
  font-family: "Special Elite", system-ui;
  cursor: pointer;
  border-radius: 16px;
  border: none;
  background-color: #27333b;
  color: #f1f1f1;
  user-select: none;
`;

export const ErrorMessage = styled.span`
  color: #cc0000;
  font-family: "Special Elite", system-ui;
  list-style-type: disc;
  font-size: 16px;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  display: flex;
  height: 140px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const Loader = styled.span`
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
  overflow: hidden;
  display: flex;

  &::after {
    content: "";
    position: absolute;
    inset: 8px;
    margin: auto;
    background: #222b32;
  }

  &::before {
    content: "";
    position: absolute;
    inset: -15px;
    margin: auto;
    background: #de3500;
    animation: ${diamondLoader} 2s linear infinite;
  }
`;
