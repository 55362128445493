import React from 'react';
import * as S from './styles';

export const Loader: React.FC = () => {
  return (
    <S.Conitainer>
      <S.Loader></S.Loader>

      <S.Text>Loading...</S.Text>
    </S.Conitainer>
  );
};
